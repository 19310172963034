import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientName from "src/components/misc/ClientName";
import ClientProfilePicture from "src/components/misc/ClientProfilePicture";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import {
  closeChat,
  openChat,
  selectSelectedChat,
  toggleExpanded,
} from "src/slices/chatSlice";
import { updateClient } from "src/slices/clientSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ChatMoreButton } from "./ChatMoreButton";
import { ChatFlyout } from "./flyout/ChatFlyout";
import SaveForLaterChips from "./SaveForLaterChips";

type Props = {
  dragHandleRef?: React.RefObject<HTMLButtonElement>;
};

export function ClientChatHeader({ dragHandleRef }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get("chat_id");
  const selectedChat = useAppSelector(selectSelectedChat);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.trainer_id,
  );
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const trainerNames = useAppSelector((state) => state.trainers.trainerNames);
  const [flyOutAnchor, setFlyOutAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const client = useAppSelector((state) =>
    selectClientById(state, selectedChat?.id ?? ""),
  );
  const [chatNotes, setChatNotes] = useState("");
  const [editingChatNotes, setEditingChatNotes] = useState(false);

  // useHotkeys("c", () => {
  //   dispatch(toggleExpanded());
  // });

  useEffect(() => {
    if (chatVisualState === "full" && chat_id) {
      dispatch(openChat({ chatId: chat_id }));
    } else if (chatVisualState === "full" && !chat_id) {
      dispatch(closeChat());
    }
  }, [chatVisualState, chat_id, dispatch, trainerId]);

  if (!selectedChat) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      <ButtonBase
        onClick={() => {
          dispatch(toggleExpanded());
        }}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          px: 1,
          py: 0.5,
          borderBottom: chatVisualState === "drag_minimized" ? 0 : 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Grid container spacing={1} alignItems="center">
            {/* <Grid item xs={"auto"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  if (mediaUploadUi === "show") {
                    dispatch(setMediaUploadUi("hide"));
                  } else {
                    if (chatVisualState === "full") {
                      navigate("/chat");
                    } else {
                      dispatch(closeChat());
                    }
                  }
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
              >
                <ArrowBackRoundedIcon fontSize="inherit" />
              </IconButton>
            </Grid> */}
            <Grid
              item
              xs={"auto"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {!selectedChat.isGroupChat && (
                <>
                  <ButtonBase
                    sx={{
                      borderRadius: "18px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();

                      if (chatVisualState !== "full") {
                        navigate("/clients/" + selectedChat.id);
                      } else {
                        setFlyOutAnchor(event.currentTarget);
                      }
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onTouchStart={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {!selectedChat.isGroupChat ? (
                      <ClientProfilePicture
                        dimension={32}
                        userId={selectedChat.id}
                      />
                    ) : (
                      <img
                        style={{
                          position: "relative",
                          borderRadius: "18px",
                        }}
                        src={
                          selectedChat.clientHeadshotURL
                            ? selectedChat.clientHeadshotURL
                            : "/assets/profile.png"
                        }
                        width={36}
                        height={36}
                        alt="client headshot"
                      />
                    )}
                  </ButtonBase>
                  <Popover
                    anchorEl={flyOutAnchor}
                    open={Boolean(flyOutAnchor)}
                    onClose={() => {
                      setFlyOutAnchor(null);
                    }}
                  >
                    <ChatFlyout userId={selectedChat.id} />
                  </Popover>
                </>
              )}
            </Grid>
            <Grid item xs>
              {selectedChat.isGroupChat ? (
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "start",
                    color: (theme) => theme.palette.text.primary,
                  }}
                >
                  {selectedChat.clientName}
                </Typography>
              ) : (
                <ClientName
                  userId={selectedChat?.id}
                  sx={{
                    fontWeight: "bold",
                    textAlign: "start",
                    color: (theme) => theme.palette.text.primary,
                  }}
                />
              )}
              {selectedChat.forceTrainerId && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  With{" "}
                  {getTrainerName(selectedChat.forceTrainerId, trainerNames)}
                </Typography>
              )}
              {client && !client.last_login_date && (
                <Typography
                  variant="body2"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  Not opened app (auto-sms)
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!selectedChat.isGroupChat && (
            <ChatMoreButton userId={selectedChat?.id} />
          )}
          {(chatVisualState === "drag_expanded" ||
            chatVisualState === "drag_minimized") && (
            <IconButton
              size="small"
              ref={dragHandleRef}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              sx={{
                cursor: "move",
              }}
              color="default"
            >
              <DragIndicatorRoundedIcon
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            </IconButton>
          )}
        </Box>
      </ButtonBase>
      {client && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            py: 0.25,
            backgroundColor: (theme) => theme.palette.background.paper,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tooltip
            title={editingChatNotes ? "Save chat notes" : "Chat notes"}
            disableInteractive
          >
            <IconButton
              size="small"
              onClick={() => {
                if (!editingChatNotes) {
                  setChatNotes(client.chat_notes ?? "");
                  setEditingChatNotes(true);
                } else {
                  dispatch(
                    updateClient({
                      user_id: selectedChat?.id ?? "",
                      chat_notes: chatNotes,
                    }),
                  );

                  setEditingChatNotes(false);
                }
              }}
              sx={{ mr: 1 }}
            >
              {editingChatNotes ? (
                <SaveRoundedIcon />
              ) : (
                <ChatBubbleOutlineRoundedIcon fontSize="inherit" />
              )}
            </IconButton>
          </Tooltip>
          {!editingChatNotes && client?.chat_notes ? (
            <Typography
              sx={{
                overflowWrap: "break-word",
                overflowX: "hidden",
              }}
            >
              {client.chat_notes}
            </Typography>
          ) : editingChatNotes ? (
            <TextField
              size="small"
              fullWidth
              multiline
              value={chatNotes}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.stopPropagation();
                  event.preventDefault();

                  dispatch(
                    updateClient({
                      user_id: selectedChat?.id ?? "",
                      chat_notes: chatNotes,
                    }),
                  );

                  setEditingChatNotes(false);
                }
              }}
              onChange={(event) => {
                setChatNotes(event.target.value);
              }}
            />
          ) : (
            <Typography variant="overline">Add chat notes</Typography>
          )}
        </Box>
      )}
      {selectedChat?.id && !selectedChat.isGroupChat && (
        <SaveForLaterChips
          userId={selectedChat.id}
          sx={{
            px: 1,
            py: 0.5,
            borderBottom: 1,
            borderColor: "divider",
          }}
        />
      )}
    </Box>
  );
}
