import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getConvertedWeight,
  stepDownSnapped,
  stepUpSnapped,
} from "@trainwell/workout-lib";
import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById } from "src/lib/exercises";
import { getOrm } from "src/lib/orm";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import {
  selectClientEquipment,
  selectClientWeightSystem,
} from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import {
  addressSetFeedback,
  deleteSets,
  duplicateSets,
  makeSelectLogSets,
  makeSelectSwappedToExerciseMasterId,
  selectSetById,
  updateIntensity,
  updateMinutes,
  updateReps,
  updateRest,
  updateSeconds,
  updateTime,
  updateWeight,
} from "src/slices/workoutSlice";
import { SetLogCell } from "./SetLogCell";
import SetNotesPopover from "./SetNotesPopover";
import SetTextField from "./SetTextField";

type Props = {
  setId: string;
  sectionId: string;
  exerciseId: string;
  exerciseMasterID: string;
  setIndex: number;
  isLastSet: boolean;
};

const SetCell = memo(function SetCell({
  setId,
  sectionId,
  exerciseId,
  exerciseMasterID,
  setIndex,
  isLastSet,
}: Props) {
  const { logId } = useParams();
  const dispatch = useAppDispatch();
  const exerciseSource = getExerciseById(exerciseMasterID);
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const showEstimatedOrm = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.settings.enable_estimated_max,
  );
  const equipment = useAppSelector(selectClientEquipment);
  const weightSystem = useAppSelector(selectClientWeightSystem);
  const set = useAppSelector((state) => selectSetById(state, setId))!;
  //   const swappedToExerciseMasterId = useAppSelector(
  // (st)

  const weightStatus = workoutLib.sets.isValidWeight({
    set: set,
    equipment: equipment ?? {},
    exerciseSource: exerciseSource!,
    weightSystem: weightSystem,
  });
  const repsStatus = workoutLib.sets.isValidReps(set, exerciseSource!);
  const timeStatus = workoutLib.sets.isValidTime(set, exerciseSource!);
  const selectLogSets = useMemo(makeSelectLogSets, []);
  const logSets = useAppSelector((state) =>
    selectLogSets(state, logId, sectionId, exerciseId, setId),
  );
  const selectSwappedToExerciseMasterId = useMemo(
    makeSelectSwappedToExerciseMasterId,
    [],
  );
  const swappedToExerciseMasterId = useAppSelector((state) =>
    selectSwappedToExerciseMasterId(state, logId, sectionId, exerciseId),
  );

  const bestOrm = useAppSelector((state) =>
    state.client.client?.best_sets
      ? state.client.client.best_sets[exerciseMasterID]?.estimated_orm
      : undefined,
  );

  if (!exerciseSource) {
    return <Typography>Loading</Typography>;
  }

  const values = workoutLib.exercises.getValuesForExercise(exerciseSource);

  const availableWeights = workoutLib.exercises.getAvailableWeights({
    clientEquipment: equipment,
    exerciseSourceId: exerciseMasterID,
  })?.weights;

  function checkUpdate() {
    console.log("Fix set");
    if (!logId) {
      return;
    }

    dispatch(
      addressSetFeedback({
        logId: logId,
        setId: setId,
      }),
    );
  }

  const orm = getOrm(
    {
      weight: set.weight_target,
      time: set.time_target,
      reps: set.reps_target,
    },
    undefined,
    exerciseSource,
  )!;

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            width: showEstimatedOrm ? `${25 + 42}px` : "25px",
            ml: showEstimatedOrm ? "-42px" : undefined,
            pr: 1,
            transitionProperty: "color",
            transitionDuration: "0.3s",
            transitionTimingFunction: "ease-in-out",
            fontWeight: "bold",
            color: "text.secondary",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          #{setIndex + 1}
          {showEstimatedOrm && (
            <Tooltip
              title={
                bestOrm && orm > bestOrm
                  ? "Estimated max. This set will be a PR"
                  : "Estimated max"
              }
              placement="left"
              disableInteractive
            >
              <Typography
                component={"span"}
                variant="overline"
                sx={{
                  ml: 1,
                  color: bestOrm && orm > bestOrm ? "#9D7F10" : undefined,
                  fontWeight: bestOrm && orm > bestOrm ? "bold" : undefined,
                }}
              >
                {workoutLib.sets.getEstimatedMaxString(
                  exerciseSource,
                  orm,
                  weightSystem,
                )}
              </Typography>
            </Tooltip>
          )}
        </Typography>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          {isTemplate && values.difficultyValue && (
            <Box>
              <SetTextField
                status={
                  workoutLib.sets.isValidIntensity(set) ? "valid" : "warn"
                }
                label={setIndex !== 0 ? undefined : "Intensity"}
                name="setCell.intensity"
                value={Math.round((set.intensity ?? 0) * 100)}
                onChange={(value) => {
                  dispatch(
                    updateIntensity({
                      intensity: Number(value.replace(/[^\d.]/g, "")),
                      sectionId: sectionId,
                      exerciseId: exerciseId,
                      setId: setId,
                    }),
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          {values.reps &&
            (!isTemplate || values.difficultyValue !== "reps") && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SetTextField
                  status={repsStatus.status}
                  label={
                    setIndex !== 0
                      ? undefined
                      : exerciseSource.reps_alternating ||
                          exerciseSource.reps_per_side
                        ? "Reps/side"
                        : "Reps"
                  }
                  fieldId={`${set.set_id}-reps`}
                  name="setCell.reps"
                  value={set.reps_target ?? ""}
                  onChange={(value) => {
                    checkUpdate();

                    dispatch(
                      updateReps({
                        reps:
                          value === ""
                            ? null
                            : Number(value.replace(/[^\d.]/g, "")),
                        sectionId: sectionId,
                        exerciseId: exerciseId,
                        setId: setId,
                      }),
                    );
                  }}
                  sx={{
                    maxWidth: "110px",
                  }}
                  onStep={(direction) => {
                    checkUpdate();

                    if (direction === "up") {
                      const snappedUpValue = stepUpSnapped({
                        value: set.reps_target ?? 0,
                        steps: [1, 2, 3, 4, 5, 6, 8, 10, 12, 15],
                        stepUpBy: 5,
                      });

                      dispatch(
                        updateReps({
                          reps: snappedUpValue,
                          sectionId: sectionId,
                          exerciseId: exerciseId,
                          setId: setId,
                        }),
                      );
                    } else {
                      const snappedDownValue = stepDownSnapped({
                        value: set.reps_target ?? 0,
                        steps: [1, 2, 3, 4, 5, 6, 8, 10, 12, 15],
                        stepDownBy: 5,
                      });

                      dispatch(
                        updateReps({
                          reps: snappedDownValue,
                          sectionId: sectionId,
                          exerciseId: exerciseId,
                          setId: setId,
                        }),
                      );
                    }
                  }}
                />
              </Box>
            )}
          {values.time && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SetTextField
                status={timeStatus.status}
                label={setIndex !== 0 ? undefined : "Min"}
                name="setCell.minutes"
                value={Math.floor((set.time_target ?? 0) / 60)}
                onChange={(value) => {
                  checkUpdate();

                  dispatch(
                    updateMinutes({
                      minutes: Math.round(Number(value.replace(/[^\d.]/g, ""))),
                      sectionId: sectionId,
                      exerciseId: exerciseId,
                      setId: setId,
                    }),
                  );
                }}
                sx={{
                  maxWidth: "54px",
                }}
              />
              <SetTextField
                status={timeStatus.status}
                label={setIndex !== 0 ? undefined : "Sec"}
                name="setCell.seconds"
                fieldId={`${set.set_id}-time`}
                value={Math.round((set.time_target ?? 0) % 60)}
                onChange={(value) => {
                  checkUpdate();

                  dispatch(
                    updateSeconds({
                      seconds: Math.round(Number(value.replace(/[^\d.]/g, ""))),
                      sectionId: sectionId,
                      exerciseId: exerciseId,
                      setId: setId,
                    }),
                  );
                }}
                sx={{
                  maxWidth: "54px",
                  ml: "2px",
                }}
                onStep={(direction) => {
                  checkUpdate();

                  if (direction === "up") {
                    const snappedUpValue = stepUpSnapped({
                      value: set.time_target ?? 0,
                      steps: [5, 10, 15, 20, 25, 30, 45, 60, 75, 90, 105, 120],
                      stepUpBy: 30,
                    });

                    dispatch(
                      updateTime({
                        seconds: snappedUpValue,
                        sectionId: sectionId,
                        exerciseId: exerciseId,
                        setId: setId,
                      }),
                    );
                  } else {
                    const snappedDownValue = stepDownSnapped({
                      value: set.time_target ?? 0,
                      steps: [5, 10, 15, 20, 25, 30, 45, 60, 75, 90, 105, 120],
                      stepDownBy: 30,
                    });

                    dispatch(
                      updateTime({
                        seconds: snappedDownValue,
                        sectionId: sectionId,
                        exerciseId: exerciseId,
                        setId: setId,
                      }),
                    );
                  }
                }}
              />
            </Box>
          )}
          {values.weight &&
            (!isTemplate || values.difficultyValue !== "weight") && (
              <Box>
                <SetTextField
                  status={weightStatus.status}
                  label={
                    setIndex !== 0
                      ? undefined
                      : `Weight${
                          exerciseSource.weight_per_side === true ? "/side" : ""
                        } (${weightSystem === "imperial" ? "lb" : "kg"})`
                  }
                  fieldId={`${set.set_id}-weight`}
                  name="setCell.weight"
                  value={
                    set.weight_target === null
                      ? ""
                      : getConvertedWeight({
                          weight: set.weight_target,
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        })
                  }
                  onChange={(value) => {
                    checkUpdate();

                    dispatch(
                      updateWeight({
                        weight:
                          value === ""
                            ? null
                            : getConvertedWeight({
                                weight: Number(value.replace(/[^\d.]/g, "")),
                                fromSystem: weightSystem,
                                toSystem: "imperial",
                              }),
                        sectionId: sectionId,
                        exerciseId: exerciseId,
                        setId: setId,
                      }),
                    );
                  }}
                  dropdownOptions={availableWeights?.map((weight) =>
                    getConvertedWeight({
                      weight: weight ?? 0,
                      fromSystem: "imperial",
                      toSystem: weightSystem,
                      round: true,
                    }).toString(),
                  )}
                  allowDecimal
                  stepUpDisabled={
                    !set.weight_target ||
                    (availableWeights?.length
                      ? getConvertedWeight({
                          weight: set.weight_target,
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        }) >=
                        getConvertedWeight({
                          weight: availableWeights[availableWeights.length - 1],
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        })
                      : false)
                  }
                  stepDownDisabled={
                    !set.weight_target ||
                    (availableWeights?.length
                      ? getConvertedWeight({
                          weight: set.weight_target,
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        }) <=
                        getConvertedWeight({
                          weight: availableWeights[0],
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        })
                      : getConvertedWeight({
                          weight: set.weight_target,
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        }) <= (weightSystem === "imperial" ? 2.5 : 1))
                  }
                  onStep={(direction, setLocalValue) => {
                    checkUpdate();

                    if (direction === "up") {
                      const convertedAvailableWeights = availableWeights?.map(
                        (weight) =>
                          getConvertedWeight({
                            weight: weight,
                            fromSystem: "imperial",
                            toSystem: weightSystem,
                            round: true,
                          }),
                      );

                      const convertedValue = getConvertedWeight({
                        weight: set.weight_target ?? 0,
                        fromSystem: "imperial",
                        toSystem: weightSystem,
                        round: true,
                      });

                      let snappedUpValue = 0;

                      if (convertedAvailableWeights?.length) {
                        // Only snap within the available weights
                        snappedUpValue = stepUpSnapped({
                          value: convertedValue,
                          steps: convertedAvailableWeights,
                        });
                      } else {
                        // Tune snap behavior based on weight system
                        if (weightSystem === "imperial") {
                          snappedUpValue = stepUpSnapped({
                            value: convertedValue,
                            steps: [
                              2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25,
                            ],
                            stepUpBy: 5,
                          });
                        } else {
                          snappedUpValue = stepUpSnapped({
                            value: convertedValue,
                            steps: [1, 2, 3, 4, 5, 6],
                            stepUpBy: 2,
                          });
                        }
                      }

                      const imperialValue = getConvertedWeight({
                        weight: snappedUpValue,
                        fromSystem: weightSystem,
                        toSystem: "imperial",
                      });

                      dispatch(
                        updateWeight({
                          weight: imperialValue,
                          sectionId: sectionId,
                          exerciseId: exerciseId,
                          setId: setId,
                        }),
                      );

                      const newDisplayValue = getConvertedWeight({
                        weight: imperialValue,
                        fromSystem: "imperial",
                        toSystem: weightSystem,
                        round: true,
                      });

                      setLocalValue?.(newDisplayValue.toString());
                    } else {
                      const convertedWeights = availableWeights?.map((weight) =>
                        getConvertedWeight({
                          weight: weight,
                          fromSystem: "imperial",
                          toSystem: weightSystem,
                          round: true,
                        }),
                      );

                      const convertedValue = getConvertedWeight({
                        weight: set.weight_target ?? 0,
                        fromSystem: "imperial",
                        toSystem: weightSystem,
                        round: true,
                      });

                      let snappedDownValue = 0;

                      if (convertedWeights?.length) {
                        // Only snap within the available weights
                        snappedDownValue = stepDownSnapped({
                          value: convertedValue,
                          steps: convertedWeights,
                        });
                      } else {
                        // Tune snap behavior based on weight system
                        if (weightSystem === "imperial") {
                          snappedDownValue = stepDownSnapped({
                            value: convertedValue,
                            steps: [
                              2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25,
                            ],
                            stepDownBy: 5,
                          });
                        } else {
                          snappedDownValue = stepDownSnapped({
                            value: convertedValue,
                            steps: [1, 2, 3, 4, 5, 6],
                            stepDownBy: 2,
                          });
                        }
                      }

                      const imperialValue = getConvertedWeight({
                        weight: snappedDownValue,
                        fromSystem: weightSystem,
                        toSystem: "imperial",
                      });

                      dispatch(
                        updateWeight({
                          weight: imperialValue,
                          sectionId: sectionId,
                          exerciseId: exerciseId,
                          setId: setId,
                        }),
                      );

                      const newDisplayValue = getConvertedWeight({
                        weight: imperialValue,
                        fromSystem: "imperial",
                        toSystem: weightSystem,
                        round: true,
                      });

                      setLocalValue?.(newDisplayValue.toString());
                    }
                  }}
                  sx={{
                    maxWidth: "110px",
                  }}
                />
              </Box>
            )}
          <SetTextField
            status={workoutLib.sets.isValidRest(set).status}
            label={setIndex !== 0 ? undefined : "Rest (s)"}
            name="setCell.rest"
            fieldId={`${set.set_id}-rest`}
            value={set.rest_target ?? ""}
            onChange={(value) => {
              checkUpdate();

              dispatch(
                updateRest({
                  rest: Math.round(Number(value.replace(/[^\d.]/g, ""))),
                  sectionId: sectionId,
                  exerciseId: exerciseId,
                  setId: setId,
                }),
              );
            }}
            sx={{
              maxWidth: "90px",
            }}
          />
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
          <IconButton
            onClick={() => {
              dispatch(
                duplicateSets({
                  setIds: [setId],
                }),
              );
            }}
            name="setCell.copy"
            size="small"
          >
            <ContentCopyRoundedIcon sx={{ fontSize: 16 }} />
          </IconButton>
          <SetNotesPopover
            set={set}
            setId={setId}
            exerciseId={exerciseId}
            sectionId={sectionId}
          />
          <IconButton
            disabled={isLastSet}
            onClick={() => {
              checkUpdate();

              dispatch(
                deleteSets({
                  setIds: [setId],
                }),
              );
            }}
            name="setCell.delete"
            size="small"
          >
            <DeleteRoundedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      </Box>
      {logSets.length > 0 && (
        <Stack spacing={0.5} direction={"column"} sx={{ mb: 1 }}>
          {logSets.map((setLog) => (
            <SetLogCell
              key={setLog.set_log_id}
              setLog={setLog}
              exerciseMasterId={swappedToExerciseMasterId || exerciseMasterID}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
});

export default SetCell;
