import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import LibraryAddCheckRoundedIcon from "@mui/icons-material/LibraryAddCheckRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  closeChat,
  openChat,
  resetMassMessaging,
  setChatView,
  setCheckInMode,
  toggleExpanded,
  toggleFullscreen,
  toggleHideClientChat,
  toggleSelectingChats,
} from "src/slices/chatSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ThreadsButton } from "./threads/ThreadsButton";

type Props = {
  condensed?: boolean;
  dragHandleRef?: React.RefObject<HTMLButtonElement>;
};

export function ChatListHeader({ condensed, dragHandleRef }: Props) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get("chat_id");
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.trainer_id,
  );
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const chatView = useAppSelector((state) => state.chat.view);
  const checkInMode = useAppSelector((state) => state.chat.checkInMode);
  const hideClientChat = useAppSelector((state) => state.chat.hideClientChat);
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const actionItemStatus = useAppSelector((state) => state.actionItems.status);
  const currentTab = useAppSelector((state) => state.chat.currentTab);

  useEffect(() => {
    if (chatVisualState === "full" && chat_id) {
      dispatch(openChat({ chatId: chat_id }));
    } else if (chatVisualState === "full" && !chat_id) {
      dispatch(closeChat());
    }
  }, [chatVisualState, chat_id, dispatch, trainerId]);

  return (
    <ButtonBase
      onClick={() => {
        dispatch(toggleExpanded());
      }}
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        display: "flex",
        alignItems: "center",
        width: "100%",
        px: 1,
        py: 1,
        borderBottom: chatVisualState === "drag_minimized" ? undefined : 1,
        borderColor: "divider",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {chatView === "threads" ? (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={"auto"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(setChatView("default"));
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
              >
                <ArrowBackRoundedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", textAlign: "start" }}
              >
                Unread threads
              </Typography>
            </Grid>
          </Grid>
        ) : chatView === "mass_message" ? (
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={"auto"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(setChatView("default"));
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
              >
                <ArrowBackRoundedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", textAlign: "start" }}
              >
                Mass message
              </Typography>
            </Grid>
          </Grid>
        ) : chatView === "check_in" ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* {showActionItemCount && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: (theme) =>
                mostSevereActionItemStatus === "error"
                  ? theme.palette.error.main
                  : mostSevereActionItemStatus === "warn"
                    ? theme.palette.warning.main
                    : theme.palette.text.primary,
              mr: 1,
            }}
          >
            <Typography
              sx={{ mr: condensed ? 0.5 : 1, fontWeight: "bold" }}
            >
              {clientAlertCount}
            </Typography>
            <AssignmentRoundedIcon
              fontSize={condensed ? "inherit" : undefined}
            />
          </Box>
        )} */}
            {!condensed && <Typography variant="h1">Check in</Typography>}
            {!condensed && (
              <Button
                variant="text"
                size="small"
                sx={{ ml: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  if (checkInMode) {
                    dispatch(setCheckInMode(undefined));
                  } else {
                    dispatch(setChatView("default"));
                  }

                  dispatch(resetMassMessaging());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                disabled={actionItemStatus !== "succeeded"}
              >
                {checkInMode ? "Back" : "Close"}
              </Button>
            )}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!condensed && <Typography variant="h1">Chat</Typography>}
            {!condensed && (
              <Button
                variant="text"
                size="small"
                sx={{ ml: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(setChatView("check_in"));
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                disabled={actionItemStatus !== "succeeded"}
              >
                Check in
              </Button>
            )}
          </Box>
        )}
      </Box>
      {!condensed && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {currentTab === "clients" && chatView === "default" && (
            <ThreadsButton />
          )}
          {currentTab === "clients" && chatView === "default" && (
            <Tooltip
              disableInteractive
              title={isSelectingChats ? "Cancel" : "Select chats"}
            >
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(toggleSelectingChats());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
                sx={{ mr: 1 }}
              >
                {isSelectingChats ? (
                  <CancelRoundedIcon fontSize="inherit" />
                ) : (
                  <LibraryAddCheckRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          )}
          {chatVisualState !== "full" && (
            <Tooltip
              disableInteractive
              title={
                chatVisualState === "big_left" ? "smol" : "Big chat mode™"
              }
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(toggleFullscreen());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
                size="small"
              >
                {chatVisualState === "big_left" ? (
                  <CloseFullscreenRoundedIcon fontSize="inherit" />
                ) : (
                  <OpenInFullRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          )}
          {chatVisualState === "big_left" && (
            <Tooltip
              disableInteractive
              title={hideClientChat ? "Show chat 'c'" : "Hide chat 'c'"}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(toggleHideClientChat());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
                size="small"
              >
                {hideClientChat ? (
                  <ChatBubbleRoundedIcon fontSize="inherit" />
                ) : (
                  <MenuOpenRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          )}
          {(chatVisualState === "drag_expanded" ||
            chatVisualState === "drag_minimized") && (
            <IconButton
              size="small"
              ref={dragHandleRef}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              color="default"
              sx={{
                cursor: "move",
              }}
            >
              <DragIndicatorRoundedIcon
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            </IconButton>
          )}
        </Box>
      )}
      {condensed && (
        <Tooltip
          disableInteractive
          placement="right"
          title={hideClientChat ? "Show chat 'c'" : "Hide chat 'c'"}
        >
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();

              dispatch(toggleHideClientChat());
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            {hideClientChat ? (
              <ChatBubbleRoundedIcon />
            ) : (
              <MenuOpenRoundedIcon />
            )}
          </IconButton>
        </Tooltip>
      )}
    </ButtonBase>
  );
}
