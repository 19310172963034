import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import ControlBar from "src/components/misc/ControlBar";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import { dismissActionItemsForClient } from "src/slices/actionItemSlice";
import { closeChat, openChat, setHideClientChat } from "src/slices/chatSlice";
import {
  duplicateLatestHabitWeekPlan,
  fetchClient,
  handleDragEndInClientPage,
  setTransferInfo,
  toggleDetails,
  transferProgram,
  updateClient,
} from "src/slices/clientSlice";
import {
  closeTemplateLibrary,
  setPhaseTemplateEditing,
} from "src/slices/phaseTemplatesSlice";
import { fetchPhases, setPhaseEditing } from "src/slices/phasesSlice";
import {
  fetchAuditeeTrainer,
  selectIsAuditing,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";
import ClientOverview from "../ClientOverview/ClientOverview";
import { DialogTitleWithClose } from "../misc/DialogTitleWithClose";
import ClientControlBar from "./ClientControlBar";
import OnboardingPage from "./OnboardingPage";
import { SelectorColumn } from "./SelectorColumn/SelectorColumn";

const leftDrawerWidth = 260;
// const phaseDrawerWidth = 220;

export default function ClientLayout() {
  const { userId, logId, workoutId } = useParams();
  const isWorkoutOrLogOpen = Boolean(workoutId || logId);
  const dispatch = useAppDispatch();
  const clientTrainerId = useAppSelector(
    (state) =>
      state.client.client?.trainer_id_interim ??
      state.client.client?.trainer_id,
  );
  const currentUserId = useAppSelector((state) => state.client.client?.user_id);
  const clientDateOnboarded = useAppSelector(
    (state) => state.client.client?.account.dashboard.date_onboarded,
  );
  const clientIsCab = useAppSelector((state) => state.client.client?.is_cab);
  const trainer = useAppSelector(selectPrimaryTrainer);
  const clientStatus = useAppSelector((state) => state.client.status);
  const detailsOpen = useAppSelector((state) => state.client.detailsOpen);
  const isAuditing = useAppSelector(selectIsAuditing);
  const company = useAppSelector((state) => state.company.company);
  const openedDevCoach = useRef(false);
  const isPhaseSelected = useAppSelector(
    (state) => state.phases.phaseEditing !== null,
  );
  const transferInfo = useAppSelector((state) => state.client.transferInfo);
  const [transfering, setTransfering] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const isBigScreen = useMediaQuery("(min-width:1350px)");
  const isHugeScreen = useMediaQuery("(min-width:1550px)");
  const dashMode = useAppSelector((state) => state.app.dashMode);

  console.log("Render: client layout");

  useEffect(() => {
    if (
      clientTrainerId &&
      clientTrainerId !== trainer?.trainer_id &&
      // Enable demo trainer ghosting
      clientTrainerId !== "e034e939-4e5a-4971-92fa-e37063cc54c7"
    ) {
      dispatch(fetchAuditeeTrainer(clientTrainerId));
    }
  }, [dispatch, clientTrainerId, trainer?.trainer_id]);

  useEffect(() => {
    if (!isWorkoutOrLogOpen) {
      dispatch(setHideClientChat(false));
    }
  }, [isWorkoutOrLogOpen, userId, dispatch]);

  useEffect(() => {
    if (
      userId &&
      (clientStatus === "idle" || (currentUserId && userId !== currentUserId))
    ) {
      dispatch(fetchClient(userId));
      dispatch(fetchPhases({ userId: userId }));

      if (!isAuditing && dashMode !== "programming") {
        if (trainer && !trainer?.visited_user_ids.includes(userId)) {
          dispatch(
            updateTrainer({
              trainer_id: trainer?.trainer_id,
              visited_user_ids: trainer?.visited_user_ids.concat(userId),
            }),
          );
        }

        dispatch(
          updateClient({
            user_id: userId,
            date_last_trainer_check_in: new Date().toISOString(),
          }),
        );

        dispatch(
          dismissActionItemsForClient({
            userId,
          }),
        );
      }
    }
  }, [clientStatus, dispatch, userId, currentUserId, dashMode]);

  useEffect(() => {
    if (userId) {
      dispatch(openChat({ chatId: userId }));
    }

    return () => {
      dispatch(closeChat());
    };
  }, [dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(closeTemplateLibrary());
      dispatch(setPhaseEditing(null));
      dispatch(setPhaseTemplateEditing(null));
    };
  }, [userId, dispatch]);

  useEffect(() => {
    return monitorForElements({
      onDrop(dropResult) {
        dispatch(handleDragEndInClientPage(dropResult))
          .unwrap()
          .then((d) => {
            if (d && "transfer" in d) {
              dispatch(setTransferInfo(d));
            }
          });
      },
      canMonitor: ({ source }) =>
        [
          "habit_week",
          "habit_week_source",
          "workout",
          "workout_task",
          "workout_task_past",
          "day",
          "phase_day",
          "phase_workout",
          "new_phase_day",
          "empty_phase_day",
          "phase_workout_panel",
        ].includes(source.data.type as string),
    });
  }, [dispatch]);

  if (!clientTrainerId || currentUserId !== userId) {
    return <LoadingPage message={"Loading your client 😄"} />;
  }

  if (
    clientTrainerId !== trainer?.trainer_id &&
    !trainerHasAccess(trainer?.access_roles, "client_list") &&
    // Enable demo trainer ghosting
    clientTrainerId !== "e034e939-4e5a-4971-92fa-e37063cc54c7"
  ) {
    return (
      <Box>
        <ControlBar />
        <RestrictAccess location="client_list" showNoAccessMessage />
      </Box>
    );
  }

  if (!clientDateOnboarded) {
    return <OnboardingPage />;
  }

  if (
    window.location.hostname === "trainer.trainwell.net" ||
    window.location.hostname === "leadtrainer.trainwell.net"
  ) {
    if (company?.cab_clients_use_dev && clientIsCab) {
      if (!openedDevCoach.current) {
        openedDevCoach.current = true;
        window.open(
          `https://devtrainer.trainwell.net/clients/${userId}`,
          "_blank",
        );
      }

      return (
        <Box>
          <ControlBar />
          <Typography sx={{ m: 2, mt: "38px" }}>
            This client is currently set to use devcoach. Let support know if
            you think this is an error.
          </Typography>
          <Button
            href={`https://devtrainer.trainwell.net/clients/${userId}`}
            LinkComponent={"a"}
          >
            Go to devcoach
          </Button>
        </Box>
      );
    }
  }

  const isLogOpen = Boolean(logId);
  const isWorkoutOpen = Boolean(workoutId);

  const hidePhasesWorkoutsColumn =
    (isLogOpen && !isBigScreen) || (isWorkoutOpen && !isBigScreen);
  const hidePhaseBuilderColumn =
    (isLogOpen && !isHugeScreen) || (isWorkoutOpen && !isHugeScreen);

  const phaseBuilderOpen = isPhaseSelected;
  const phasesOpen = isLogOpen || isWorkoutOpen ? !phaseBuilderOpen : true;

  return (
    <Box sx={{ overflowX: "hidden", display: "flex" }}>
      <ClientControlBar />
      {detailsOpen && (
        <Box
          sx={{
            flexShrink: 0,
            height: "calc(100vh - 38px)",
            width: detailsOpen ? leftDrawerWidth : "48px",
            overflowY: "auto",
            mt: "38px",
            borderRight: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {userId && (
            <ClientOverview
              open={detailsOpen}
              onToggle={() => {
                dispatch(toggleDetails());
              }}
              userId={userId}
            />
          )}
        </Box>
      )}
      {/* {phaseBuilderOpen && (
        <Box
          sx={{
            width: phaseDrawerWidth,
            flexShrink: 0,
            height: "100vh",
            pt: "38px",
            borderRight: 1,
            borderColor: "divider",
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <PhaseBuilder onClose={() => {}} />
        </Box>
      )} */}
      <Box component={"main"} sx={{ flex: 1 }}>
        <Toolbar />
        <Box sx={{ display: "flex" }}>
          <SelectorColumn />
          <Outlet />
        </Box>
      </Box>
      <Dialog
        onClose={() => {
          dispatch(setTransferInfo(null));
        }}
        open={transferInfo !== null}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleWithClose
          onClose={() => {
            dispatch(setTransferInfo(null));
          }}
        >
          Cycle count
        </DialogTitleWithClose>
        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            How many cycles do you want of this phase?
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              label={"Cycles"}
              size="small"
              value={transferInfo?.cycleCount ?? 1}
              onChange={(event) => {
                if (!transferInfo) {
                  return;
                }

                dispatch(
                  setTransferInfo({
                    ...transferInfo,
                    cycleCount: Number(event.target.value),
                  }),
                );
              }}
            />
            <ButtonGroup
              variant="outlined"
              orientation="vertical"
              sx={{ ml: 0.5 }}
            >
              <Button
                size="small"
                onClick={() => {
                  if (!transferInfo) {
                    return;
                  }

                  dispatch(
                    setTransferInfo({
                      ...transferInfo,
                      cycleCount: (transferInfo.cycleCount ?? 1) + 1,
                    }),
                  );
                }}
                sx={{ p: 0, minWidth: "25px !important" }}
              >
                <KeyboardArrowUpRoundedIcon sx={{ fontSize: 16 }} />
              </Button>
              <Button
                size="small"
                disabled={
                  !transferInfo?.cycleCount || transferInfo.cycleCount <= 1
                }
                onClick={() => {
                  if (!transferInfo) {
                    return;
                  }

                  dispatch(
                    setTransferInfo({
                      ...transferInfo,
                      cycleCount: Math.min(
                        (transferInfo.cycleCount ?? 1) + 1,
                        1,
                      ),
                    }),
                  );
                }}
                sx={{ p: 0, minWidth: "25px !important" }}
              >
                <KeyboardArrowDownRoundedIcon sx={{ fontSize: 16 }} />
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(setTransferInfo(null));
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={transfering}
            autoFocus
            onClick={async () => {
              if (!transferInfo) {
                return;
              }

              setTransfering(true);

              await dispatch(
                transferProgram({
                  dayIndex: transferInfo.dayIndex,
                  phaseId: transferInfo.phaseId,
                  weekPlanId: transferInfo.weekPlanId,
                  cycleCount: transferInfo.cycleCount ?? 1,
                }),
              )
                .unwrap()
                .then(() => {
                  dispatch(setTransferInfo(null));
                  setTransfering(false);

                  enqueueSnackbar(`Added phase`, {
                    variant: "success",
                  });
                })
                .catch(async (err) => {
                  if (err.requiredAdditionalWeeks) {
                    const newWeekCount = Number(err.requiredAdditionalWeeks);

                    await dispatch(duplicateLatestHabitWeekPlan(newWeekCount));

                    dispatch(
                      transferProgram({
                        dayIndex: transferInfo.dayIndex,
                        phaseId: transferInfo.phaseId,
                        weekPlanId: transferInfo.weekPlanId,
                        cycleCount: transferInfo.cycleCount ?? 1,
                      }),
                    );

                    dispatch(setTransferInfo(null));
                    setTransfering(false);

                    enqueueSnackbar(`Added phase and ${newWeekCount} weeks`, {
                      variant: "success",
                    });
                  }
                });
            }}
          >
            Insert {transferInfo?.cycleCount ?? 1} cycle
            {(transferInfo?.cycleCount ?? 1) === 1 ? "" : "s"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
