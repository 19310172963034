import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { handleDragEndInTemplateLibrary } from "src/slices/phaseTemplatesSlice";
import FolderPage from "./FolderPage";
import PhaseTemplateBuilder from "./PhaseTemplateBuilder/PhaseTemplateBuilder";
import { Sidebar } from "./Sidebar/Sidebar";
import TagPage from "./TagPage";
import TemplateLibraryHomePage from "./TemplateLibraryHomePage";

const leftDrawerWidth = 260;
const rightDrawerWidth = 320;

export default function TemplateLibraryLayout() {
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const openFolderId = useAppSelector(
    (state) => state.phaseTemplates.openFolderId,
  );
  const openTagId = useAppSelector((state) => state.phaseTemplates.openTagId);
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const phaseTemplateEditing = useAppSelector(
    (state) => state.phaseTemplates.phaseTemplateEditing,
  );

  useEffect(() => {
    return monitorForElements({
      onDrop(dropResult) {
        dispatch(handleDragEndInTemplateLibrary(dropResult));
      },
      canMonitor: ({ source }) =>
        [
          "page",
          "phase_template_day",
          "empty_phase_template_day",
          "workout_template",
          "phase_template",
          "phase_template_workout_template",
          "folder_sidebar",
          "folder",
          "new_phase_template_day",
        ].includes(source.data.type as string),
    });
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: isDialog ? undefined : "100vh",
      }}
    >
      {!isDialog && (
        <ControlBar
          leftContent={
            <IconButton
              aria-label="toggle drawer"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
              size="small"
            >
              {drawerOpen ? <ChevronLeftRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
          }
        />
      )}
      {drawerOpen && (
        <Box
          sx={{
            width: leftDrawerWidth,
            flexShrink: 0,
            borderRight: 1,
            borderColor: "divider",
            mt: isDialog ? "35px" : "38px",
            overflowY: "auto",
            height: "calc(100vh - 38px)",
          }}
        >
          <Sidebar />
        </Box>
      )}
      <Box
        component={"main"}
        sx={{
          flex: 1,
          overflow: "hidden",
          height: "100%",
          paddingTop: !isDialog ? "38px" : undefined,
        }}
      >
        <Box
          sx={{
            height: "100%",
            overflowX: "hidden",
            overflowY: "hidden",
            backgroundColor: (theme) => theme.palette.background.default,
            width: "100%",
          }}
        >
          {!isDialog ? (
            <Outlet />
          ) : openFolderId ? (
            <FolderPage />
          ) : openTagId ? (
            <TagPage />
          ) : (
            <TemplateLibraryHomePage />
          )}
        </Box>
      </Box>
      {phaseTemplateEditing !== null && (
        <Box
          sx={{
            width: rightDrawerWidth,
            flexShrink: 0,
            borderLeft: 1,
            borderColor: "divider",
            mt: isDialog ? "35px" : "38px",
            overflowY: "auto",
            height: "calc(100vh - 38px)",
          }}
        >
          <PhaseTemplateBuilder onClose={() => undefined} />
        </Box>
      )}
    </Box>
  );
}
