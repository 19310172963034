import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { disableNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview";
import { preventUnhandled } from "@atlaskit/pragmatic-drag-and-drop/prevent-unhandled";
import type { DragLocationHistory } from "@atlaskit/pragmatic-drag-and-drop/types";
import { Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { useWindowDimensions } from "src/hooks/useWindowDimensions";
import ChatBar from "./ChatBar";

type Coordinates = {
  x: number;
  y: number;
};

const defaultCoordinates: Coordinates = {
  x: 0,
  y: 0,
};

export function ChatDraggable() {
  const ref = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLButtonElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const chatVisualState = useAppSelector((state) => state.chat.chatMode);
  const expanded =
    chatVisualState === "drag_expanded" ||
    chatVisualState === "big_left" ||
    chatVisualState === "full";
  const windowDimensions = useWindowDimensions();
  const [initialCoords, setInitialCoords] =
    useState<Coordinates>(defaultCoordinates);

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!element || !dragHandle) {
      return;
    }

    const maxX = windowDimensions.width - ref.current.clientWidth;
    const maxY = windowDimensions.height - ref.current.clientHeight;

    return draggable({
      element: dragHandle,
      onDragStart: ({ location }) => {
        const newLocation = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        ref.current?.style.setProperty(
          "--local-translating-x",
          `${newLocation.x}px`,
        );
        ref.current?.style.setProperty(
          "--local-translating-y",
          `${newLocation.y}px`,
        );

        setDragging(true);
      },
      onDrag: ({ location }) => {
        const newLocation = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        ref.current?.style.setProperty(
          "--local-translating-x",
          `${newLocation.x}px`,
        );
        ref.current?.style.setProperty(
          "--local-translating-y",
          `${newLocation.y}px`,
        );
      },
      onDrop: ({ location }) => {
        preventUnhandled.stop();

        const newCoords = getProposedCoords({
          initialCoords: {
            x: initialCoords.x,
            y: initialCoords.y,
          },
          location,
          maxX,
          maxY,
        });

        setInitialCoords(newCoords);

        setDragging(false);

        ref.current?.style.removeProperty("--local-translating-x");
        ref.current?.style.removeProperty("--local-translating-y");
      },
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        // we will be moving the line to indicate a drag
        // we can disable the native drag preview
        disableNativeDragPreview({ nativeSetDragImage });
        // we don't want any native drop animation for when the user
        // does not drop on a drop target. we want the drag to finish immediately
        preventUnhandled.start();
      },
    });
  }, [
    initialCoords.x,
    initialCoords.y,
    windowDimensions.width,
    windowDimensions.height,
  ]);

  return (
    <Paper
      ref={ref}
      sx={{
        "--local-initial-x": initialCoords.x,
        "--local-initial-y": initialCoords.y,
        marginTop: expanded ? "-450px" : "0px",
        backgroundColor: (theme) => theme.palette.background.default,
        borderRadius: 1,
        border: 1,
        borderColor: "divider",
        overflow: "hidden",
        zIndex: 1260,
        boxShadow: dragging ? 12 : 6,
        position: "absolute",
        bottom: dragging ? "var(--local-translating-y)" : initialCoords.y,
        right: dragging ? "var(--local-translating-x)" : initialCoords.x,
        pointerEvents: dragging ? "none" : undefined,
      }}
    >
      <ChatBar dragHandleRef={dragHandleRef} />
    </Paper>
  );
}

function getProposedCoords({
  initialCoords,
  location,
  maxX,
  maxY,
}: {
  initialCoords: Coordinates;
  location: DragLocationHistory;
  maxX: number;
  maxY: number;
}): Coordinates {
  const diffX = location.current.input.clientX - location.initial.input.clientX;
  const proposedX = initialCoords.x - diffX;

  const diffY = location.current.input.clientY - location.initial.input.clientY;
  const proposedY = initialCoords.y - diffY;

  return {
    x: Math.min(Math.max(proposedX, 0), maxX),
    y: Math.min(Math.max(proposedY, 0), maxY),
  };
}
