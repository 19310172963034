import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ListItem as MuiListItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getSmartTagDetails } from "src/lib/phaseTags";
import {
  buildFolderHierarchy,
  type FolderHeirarchy,
} from "src/lib/templateLibraryFolder";
import {
  createWorkoutTemplate,
  selectCanEditTrainwellLibrary,
  selectUsedPhaseTemplateTags,
  setPhaseTemplateEditing,
  setTemplateNavigation,
  setTemplateTab,
} from "src/slices/phaseTemplatesSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { openWorkoutTemplate } from "src/slices/workoutBuilderSlice";
import FolderEditDialog from "../FolderEditDialog";

export function Sidebar() {
  const dispatch = useAppDispatch();
  const [openFolderIds, setOpenFolderIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const phaseTemplateFolders = useAppSelector(
    (state) => state.phaseTemplates.templateLibraryFolders,
  );
  const location = useLocation();
  const match = matchPath(
    { path: "/template-library/folders/:phaseTemplateFolderId" },
    location.pathname,
  );
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);
  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const trainerId = useAppSelector(
    (state) => selectPrimaryTrainer(state)!.trainer_id,
  );
  const possibleTags = useAppSelector((state) =>
    selectUsedPhaseTemplateTags(
      state,
      currentTab === "trainer" ? trainerId : "trainwell",
    ),
  );
  const [newAnchorEl, setNewAnchorEl] = useState<null | HTMLElement>(null);
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const canEditTrainwellLibrary = useAppSelector(selectCanEditTrainwellLibrary);

  const filteredPhaseTemplateFolders = phaseTemplateFolders.filter((f) => {
    if (currentTab === "trainer") {
      return f.trainer_id === trainerId;
    }

    return f.trainer_id === "copilot";
  });

  const folderHierarchy = buildFolderHierarchy(filteredPhaseTemplateFolders);

  function renderFolderHierarchy(folderNodes: FolderHeirarchy[]) {
    return (
      <>
        {folderNodes.map((node) => (
          <DroppableListItem
            key={node.folder._id}
            itemId={node.folder._id}
            folderId={node.folder._id}
            label={node.folder.name}
            path={
              isDialog ? "" : `/template-library/folders/${node.folder._id}`
            }
            icon={<FolderRoundedIcon fontSize="inherit" />}
            onClick={() => {
              if (isDialog) {
                dispatch(
                  setTemplateNavigation({
                    openFolderId: node.folder._id,
                    openTagId: null,
                  }),
                );
              }

              if (!openFolderIds.includes(node.folder._id)) {
                setOpenFolderIds((prev) => [...prev, node.folder._id]);
              }
            }}
            onSecondaryActionClick={() => {
              if (node.children.length > 0) {
                setOpenFolderIds((prev) => {
                  if (prev.includes(node.folder._id)) {
                    return prev.filter((id) => id !== node.folder._id);
                  } else {
                    return [...prev, node.folder._id];
                  }
                });
              }
            }}
            open={openFolderIds.includes(node.folder._id)}
            nestedItems={
              node.children.length ? renderFolderHierarchy(node.children) : null
            }
            nested={node.level ?? undefined}
          />
        ))}
      </>
    );
  }

  return (
    <Box sx={{ overflow: "auto" }}>
      <Tabs
        onChange={(event, newValue) => {
          if (newValue !== currentTab && !isDialog) {
            navigate("/template-library");
          }

          dispatch(setTemplateTab(newValue));
        }}
        value={currentTab}
        aria-label="tabs"
        centered
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label={"trainwell Library"} value="trainwell" />
        <Tab label={"My library"} value="trainer" />
      </Tabs>
      <Box sx={{ m: 2 }}>
        <Button
          variant="contained"
          fullWidth
          startIcon={<AddRoundedIcon />}
          onClick={(event) => {
            setNewAnchorEl(event.currentTarget);
          }}
          disabled={!canEditTrainwellLibrary && currentTab === "trainwell"}
        >
          New
        </Button>
      </Box>
      <DroppableHome />
      <Typography variant="h6" sx={{ ml: 1, mt: 1 }}>
        Folders
      </Typography>
      <List sx={{ p: 0 }}>{renderFolderHierarchy(folderHierarchy)}</List>
      <Typography variant="h6" sx={{ ml: 1, mt: 3 }}>
        Tags
      </Typography>
      <List sx={{ p: 0 }}>
        {possibleTags.map((tag) => {
          const tagDetails = getSmartTagDetails(tag);

          return (
            <ListItem
              key={tag}
              label={tagDetails.label}
              itemId={tag}
              path={
                isDialog
                  ? ""
                  : `/template-library/tags/${encodeURIComponent(tag)}`
              }
              onClick={() => {
                if (isDialog) {
                  dispatch(
                    setTemplateNavigation({
                      openFolderId: null,
                      openTagId: tag,
                    }),
                  );
                }
              }}
              icon={
                tagDetails.icon ? (
                  <tagDetails.icon.type fontSize="inherit" />
                ) : (
                  <LabelRoundedIcon fontSize="inherit" />
                )
              }
            />
          );
        })}
      </List>
      <Menu
        anchorEl={newAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(newAnchorEl)}
        onClose={() => {
          setNewAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setFolderDialogOpen(true);
            setNewAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <CreateNewFolderRoundedIcon
              fontSize="small"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            />
          </ListItemIcon>
          <ListItemText primary="Folder" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              setPhaseTemplateEditing({
                _id: null,
                date_created: new Date().toISOString(),
                date_updated: new Date().toISOString(),
                date_deleted: null,
                workout_template_ids: [],
                days_draggable: [],
                tags: [],
                name: "New phase",
                is_pinned: false,
                trainer_id: trainerId,
                trainer_id_created_by: trainerId,
                type: "multiple",
              }),
            );

            setNewAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.success.main,
                width: 20,
                height: 20,
                borderRadius: "6px",
              }}
            >
              <CalendarViewWeekRoundedIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Phase" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(
              createWorkoutTemplate({
                trainerId: trainerId,
                parentFolderId:
                  match?.params.phaseTemplateFolderId ?? undefined,
              }),
            )
              .unwrap()
              .then(({ phaseTemplate }) => {
                dispatch(
                  openWorkoutTemplate(phaseTemplate.workout_template_ids[0][0]),
                );
              });

            setNewAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.blue.main,
                width: 20,
                height: 20,
                borderRadius: "6px",
              }}
            >
              <FitnessCenterRoundedIcon
                sx={{
                  fontSize: 16,
                }}
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Workout" />
        </MenuItem>
      </Menu>
      <FolderEditDialog
        parentFolderId={match?.params.phaseTemplateFolderId ?? undefined}
        open={folderDialogOpen}
        trainerId={currentTab === "trainwell" ? "copilot" : trainerId}
        onClose={() => {
          setFolderDialogOpen(false);
        }}
      />
    </Box>
  );
}

type ListItemProps = {
  path?: string;
  label: string;
  icon: React.ReactNode;
  nestedItems?: React.ReactNode;
  open?: boolean;
  nested?: number;
  draggingOver?: boolean;
  itemId?: string;
  onClick?: () => void;
  onSecondaryActionClick?: () => void;
};

function ListItem({
  icon,
  label,
  path,
  nestedItems,
  open,
  nested,
  draggingOver,
  itemId,
  onClick,
  onSecondaryActionClick,
}: ListItemProps) {
  const location = useLocation();
  const openFolderId = useAppSelector(
    (state) => state.phaseTemplates.openFolderId,
  );
  const openTagId = useAppSelector((state) => state.phaseTemplates.openTagId);

  const selected =
    location.pathname === path ||
    Boolean(openFolderId && itemId === openFolderId) ||
    Boolean(openTagId && itemId === openTagId);

  if (!nestedItems) {
    return (
      <ListItemButton
        href={path ?? ""}
        onClick={onClick}
        selected={selected}
        sx={{
          px: 0.75,
          mx: 1,
          borderRadius: 1,
          py: 0.25,
          pl: nested ? nested * 1.5 : undefined,
          border: 2,
          borderColor: (theme) =>
            draggingOver ? theme.palette.primary.main : "transparent",
        }}
      >
        <ListItemIcon
          sx={{
            color: (theme) =>
              selected ? theme.palette.primary.main : undefined,
            minWidth: "28px",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: {
              fontWeight: selected ? "bold" : undefined,
              color: (theme) =>
                selected ? theme.palette.primary.main : undefined,
            },
          }}
          sx={{ my: 0 }}
        />
      </ListItemButton>
    );
  }

  return (
    <>
      <MuiListItem
        disablePadding
        secondaryAction={
          <IconButton edge="end" onClick={onSecondaryActionClick} size="small">
            {open ? <ExpandMoreRoundedIcon /> : <ChevronRightRoundedIcon />}
          </IconButton>
        }
      >
        <ListItemButton
          selected={selected}
          href={path ?? ""}
          onClick={onClick}
          sx={{
            px: 0.75,
            mx: 1,
            borderRadius: 1,
            py: 0.25,
            pl: nested ? nested * 1.5 : undefined,
            border: 2,
            borderColor: (theme) =>
              draggingOver ? theme.palette.primary.main : "transparent",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "28px",
              color: (theme) =>
                selected ? theme.palette.primary.main : undefined,
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              sx: {
                fontWeight: selected ? "bold" : undefined,
                color: (theme) =>
                  selected ? theme.palette.primary.main : undefined,
              },
            }}
            sx={{ my: 0 }}
          />
        </ListItemButton>
      </MuiListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {nestedItems}
      </Collapse>
    </>
  );
}

type DroppableListItemProps = ListItemProps & {
  folderId: string;
};

function DroppableListItem({ folderId, ...rest }: DroppableListItemProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "folder_sidebar", folderId: folderId }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return (
          source.data.type === "folder" ||
          source.data.type === "workout_template" ||
          source.data.type === "phase_template"
        );
      },
    });
  }, [folderId]);

  return (
    <div ref={ref}>
      <ListItem {...rest} draggingOver={isDraggedOver} />
    </div>
  );
}

function DroppableHome() {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "folder_sidebar", folderId: null }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return (
          source.data.type === "folder" ||
          source.data.type === "workout_template" ||
          source.data.type === "phase_template"
        );
      },
    });
  }, []);

  const currentTab = useAppSelector((state) => state.phaseTemplates.currentTab);
  const dispatch = useAppDispatch();
  const openForUserId = useAppSelector(
    (state) => state.phaseTemplates.openForUserId,
  );
  const isDialog = Boolean(openForUserId);

  return (
    <div ref={ref}>
      <ListItem
        draggingOver={isDraggedOver}
        label={currentTab === "trainer" ? "My home" : "trainwell home"}
        path={isDialog ? "" : "/template-library"}
        onClick={() => {
          if (isDialog) {
            dispatch(
              setTemplateNavigation({
                openFolderId: null,
                openTagId: null,
              }),
            );
          }
        }}
        icon={<HomeRoundedIcon fontSize="inherit" />}
      />
    </div>
  );
}
