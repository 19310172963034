import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import type {
  BaseEventPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PhaseTemplate, TemplateLibraryFolder } from "@trainwell/types";
import { trainerHasAccess } from "src/lib/accessRoles";
import { api } from "src/lib/trainwellApi";
import type { PhaseTemplateLocal } from "src/types/PhaseTemplateLocal";
import type { RootState } from "./store";
import { addTemplatesLocal } from "./templatesSlice";
import { selectPrimaryTrainer } from "./trainerSlice";

export const handleDragEndInTemplateLibrary = createAsyncThunk(
  "phaseTemplates/handleDragEndInTemplateLibrary",
  async (
    dropResult: BaseEventPayload<ElementDragType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const phaseTemplateEditing = state.phaseTemplates.phaseTemplateEditing;
    const allPhaseTemplates = state.phaseTemplates.phaseTemplates;
    const trainer = selectPrimaryTrainer(state);

    const source = dropResult.source;
    const sourceType = source.data.type as DndTypeTemplateLibrary;

    const target = dropResult.location.current.dropTargets[0];

    if (!target) {
      return;
    }

    const targetType = target.data.type as DndTypeTemplateLibrary;

    if (
      sourceType === "phase_template_day" &&
      targetType === "phase_template_day"
    ) {
      console.log("DND: reorder phase template days");

      const sourceIndex = source.data.index as number;

      const targetIndex = target.data.index as number;

      const newDays = [...phaseTemplateEditing!.days_draggable];

      newDays.splice(targetIndex, 0, newDays.splice(sourceIndex, 1)[0]);

      dispatch(
        updatePhaseTemplateEditing({
          days_draggable: newDays,
        }),
      );
    } else if (
      sourceType === "phase_template_workout_template" &&
      targetType === "new_phase_template_day"
    ) {
      // Drag workout from phase into a day
      console.log(
        "DND: Drag workout phase template from phase template into a new day",
      );

      const sourceWorkoutTemplateId = source.data.workoutTemplateId as string;
      const sourceDayIndex = source.data.dayIndex as number;

      const newDays = structuredClone(phaseTemplateEditing!.days_draggable);

      newDays[sourceDayIndex].templates = newDays[
        sourceDayIndex
      ].templates.filter(
        (template) => template.template_id !== sourceWorkoutTemplateId,
      );

      newDays.push({
        draggable_id: crypto.randomUUID(),
        templates: [
          {
            template_id: sourceWorkoutTemplateId,
            draggable_id: crypto.randomUUID(),
          },
        ],
      });

      dispatch(
        updatePhaseTemplateEditing({
          days_draggable: newDays,
        }),
      );
    } else if (
      sourceType === "phase_template_workout_template" &&
      (targetType === "phase_template_workout_template" ||
        targetType === "empty_phase_template_day")
    ) {
      // Reorder workouts in a phase day
      console.log("DND: reorder workout templates in a phase template day");

      const sourceWorkoutTemplateId = source.data.workoutTemplateId as string;
      const sourceIndex = source.data.index as number;
      const sourceDayIndex = source.data.dayIndex as number;

      const targetIndex = target.data.index as number;
      const targetDayIndex = target.data.dayIndex as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (targetType === "phase_template_workout_template") {
        if (sourceDayIndex === targetDayIndex) {
          finishIndex = getReorderDestinationIndex({
            startIndex: sourceIndex,
            closestEdgeOfTarget,
            indexOfTarget: targetIndex,
            axis: "vertical",
          });
        } else {
          finishIndex =
            closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
        }
      }

      const newDays = structuredClone(phaseTemplateEditing!.days_draggable);

      if (sourceDayIndex === targetDayIndex) {
        const sourceDay = newDays[sourceDayIndex];

        sourceDay.templates = reorder({
          list: sourceDay.templates,
          startIndex: sourceIndex,
          finishIndex: finishIndex,
        });
      } else {
        console.log("DND: move workout template to a different day");

        // No duplicate workout_ids allowed
        if (
          newDays[targetDayIndex].templates.some(
            (w) => w.template_id === sourceWorkoutTemplateId,
          )
        ) {
          return;
        }

        newDays[targetDayIndex].templates.splice(finishIndex, 0, {
          draggable_id: crypto.randomUUID(),
          template_id: sourceWorkoutTemplateId,
        });

        newDays[sourceDayIndex].templates = newDays[
          sourceDayIndex
        ].templates.filter((_, index) => index !== sourceIndex);
      }

      dispatch(
        updatePhaseTemplateEditing({
          days_draggable: newDays,
        }),
      );
    } else if (
      sourceType === "workout_template" &&
      (targetType === "phase_template_workout_template" ||
        targetType === "empty_phase_template_day" ||
        targetType === "new_phase_template_day")
    ) {
      console.log(
        "DND: Drag workout template from library into phase template day",
      );

      const sourceWorkoutTemplateId = source.data.workoutTemplateId as string;

      const targetIndex = target.data.index as number;
      const targetDayIndex = target.data.dayIndex as number;

      dispatch(
        updateLocalPhaseTemplate({
          workoutTemplateId: sourceWorkoutTemplateId,
          phaseTemplate: {
            deleted: true,
          },
        }),
      );

      const newDays = structuredClone(phaseTemplateEditing!.days_draggable);

      if (targetType === "new_phase_template_day") {
        newDays.push({
          draggable_id: crypto.randomUUID(),
          templates: [
            {
              template_id: sourceWorkoutTemplateId,
              draggable_id: crypto.randomUUID(),
            },
          ],
        });

        dispatch(
          updatePhaseTemplateEditing({
            days_draggable: newDays,
          }),
        );

        return;
      }

      // OK now onto the actual drag and drop logic

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (targetType === "phase_template_workout_template") {
        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      // No duplicate workout_ids allowed
      if (
        newDays[targetDayIndex].templates.some(
          (w) => w.template_id === sourceWorkoutTemplateId,
        )
      ) {
        return;
      }

      newDays[targetDayIndex].templates.splice(finishIndex, 0, {
        draggable_id: crypto.randomUUID(),
        template_id: sourceWorkoutTemplateId,
      });

      dispatch(
        updatePhaseTemplateEditing({
          days_draggable: newDays,
        }),
      );
    } else if (targetType === "folder_sidebar" || targetType === "folder") {
      const targetFolderId = target.data.folderId as string;

      if (
        sourceType === "workout_template" ||
        sourceType === "phase_template"
      ) {
        console.log("DND: move a phase into a folder");

        const sourcePhaseTemplateId = source.data.phaseTemplateId as string;

        dispatch(
          updatePhaseTemplate({
            id: sourcePhaseTemplateId,
            parentFolderId: targetFolderId,
          }),
        );
      } else if (sourceType === "folder") {
        console.log("DND: move a folder into a folder");

        const sourceFolderId = source.data.folderId as string;

        dispatch(
          updateTemplateLibraryFolder({
            id: sourceFolderId,
            parentFolderId: targetFolderId,
          }),
        );
      }
    } else if (
      sourceType === "phase_template_workout_template" &&
      targetType === "page"
    ) {
      console.log("DND: Drag workout from phase back into page");

      const sourceWorkoutTemplateId = source.data.workoutTemplateId as string;

      // null for the home page
      const targetFolderId = target.data.folderId as string;

      if (
        allPhaseTemplates.some(
          (phase) =>
            phase.type === "single" &&
            phase.workout_template_ids.flat().includes(sourceWorkoutTemplateId),
        )
      ) {
        console.log("DND: Local edit");

        dispatch(
          updateLocalPhaseTemplate({
            workoutTemplateId: sourceWorkoutTemplateId,
            phaseTemplate: {
              deleted: false,
            },
          }),
        );
      } else {
        console.log("DND: Server edit");

        dispatch(
          createWorkoutTemplate({
            trainerId: trainer!.trainer_id,
            parentFolderId: targetFolderId ?? undefined,
            workoutTemplateId: sourceWorkoutTemplateId,
          }),
        );
      }

      const newDays = structuredClone(phaseTemplateEditing!.days_draggable);

      const sourceDayIndex = source.data.dayIndex as number;

      newDays[sourceDayIndex].templates = newDays[
        sourceDayIndex
      ].templates.filter(
        (template) => template.template_id !== sourceWorkoutTemplateId,
      );

      dispatch(
        updatePhaseTemplateEditing({
          days_draggable: newDays,
        }),
      );
    }
  },
);

export const fetchPhaseTemplates = createAsyncThunk(
  "phaseTemplates/fetchPhaseTemplates",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const [
      phaseTemplates,
      trainwellPhaseTemplates,
      templateLibraryFolders,
      trainwellTemplateLibraryFolders,
    ] = await Promise.all([
      api.phaseTemplates.getMany({ trainerId: trainer.trainer_id }),
      api.phaseTemplates.getMany({ trainerId: "copilot" }),
      api.templateLibraryFolders.getMany({ trainerId: trainer.trainer_id }),
      api.templateLibraryFolders.getMany({ trainerId: "copilot" }),
    ]);

    return {
      phaseTemplates: [
        ...phaseTemplates.phase_templates,
        ...trainwellPhaseTemplates.phase_templates,
      ],
      templateLibraryFolders: [
        ...templateLibraryFolders.template_library_folders,
        ...trainwellTemplateLibraryFolders.template_library_folders,
      ],
    };
  },
);

export const createTemplateLibraryFolder = createAsyncThunk(
  "phaseTemplates/createTemplateLibraryFolder",
  async (data: {
    name: string;
    description?: string;
    parentFolderId?: string;
    trainerId: string;
  }) => {
    const { name, description, parentFolderId, trainerId } = data;

    const { template_library_folder } =
      await api.templateLibraryFolders.createOne({
        name,
        description,
        parentFolderId,
        trainerId,
      });

    return {
      templateLibraryFolder: template_library_folder,
    };
  },
);

export const updateTemplateLibraryFolder = createAsyncThunk(
  "phaseTemplates/updateTemplateLibraryFolder",
  async (data: {
    id: string;
    name?: string;
    description?: string;
    parentFolderId?: string;
    isPinned?: boolean;
  }) => {
    const { id, description, name, parentFolderId } = data;

    const { template_library_folder } =
      await api.templateLibraryFolders.updateOne({
        templateLibraryFolderId: id,
        name,
        description,
        parentFolderId,
        isPinned: data.isPinned,
      });

    return {
      templateLibraryFolder: template_library_folder,
    };
  },
);

export const deleteTemplateLibraryFolder = createAsyncThunk(
  "phaseTemplates/deleteTemplateLibraryFolder",
  async (data: { templateLibraryFolderId: string }) => {
    const { templateLibraryFolderId } = data;

    await api.templateLibraryFolders.deleteOne({
      templateLibraryFolderId: templateLibraryFolderId,
    });

    return;
  },
);

export const createWorkoutTemplate = createAsyncThunk(
  "phaseTemplates/createWorkoutTemplate",
  async (
    data: {
      parentFolderId?: string;
      trainerId: string;
      workoutTemplateId?: string;
    },
    { dispatch, getState },
  ) => {
    const { parentFolderId, trainerId, workoutTemplateId } = data;

    const state = getState() as RootState;

    const isInTrainwellLibrary =
      state.phaseTemplates.currentTab === "trainwell";

    const { phase_template, workout_template } =
      await api.phaseTemplates.createOneWorkoutTemplate({
        parentFolderId,
        trainerId: isInTrainwellLibrary ? "copilot" : trainerId,
        workoutTemplateId,
      });

    dispatch(addTemplatesLocal([workout_template]));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const createPhaseTemplate = createAsyncThunk(
  "phaseTemplates/createPhaseTemplate",
  async (
    data: {
      parentFolderId?: string;
    },
    { getState, dispatch },
  ) => {
    const { parentFolderId } = data;

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);

    const phaseEditing = state.phaseTemplates.phaseTemplateEditing;
    const isInTrainwellLibrary =
      state.phaseTemplates.currentTab === "trainwell";

    if (!phaseEditing) {
      throw new Error("No phase editing");
    }

    const { name, description, days_draggable } = phaseEditing;

    if (!description) {
      throw new Error("Invalid phase");
    }

    const workoutTemplateIds = days_draggable.map((d) =>
      d.templates.map((t) => t.template_id),
    );

    const { phase_template, new_workout_templates } =
      await api.phaseTemplates.createOne({
        name,
        description,
        workoutTemplateIds,
        trainerId: isInTrainwellLibrary ? "copilot" : trainer?.trainer_id!,
        parentFolderId,
      });

    const phaseTemplatesToDelete = state.phaseTemplates.phaseTemplates.filter(
      (pt) => pt.deleted === true,
    );

    for (const phaseTemplate of phaseTemplatesToDelete) {
      dispatch(
        deletePhaseTemplate({
          phaseTemplateId: phaseTemplate._id,
          ignoreWorkoutTemplate: true,
        }),
      );
    }

    dispatch(addTemplatesLocal(new_workout_templates));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const updatePhaseTemplate = createAsyncThunk(
  "phaseTemplates/updatePhaseTemplate",
  async (
    data: {
      id: string;
      name?: string;
      description?: string;
      daysDraggable?: DayDraggable[];
      tags?: string[];
      parentFolderId?: string;
      isPinned?: boolean;
    },
    { getState, dispatch },
  ) => {
    const { parentFolderId, name, daysDraggable, description, tags, id } = data;

    const state = getState() as RootState;

    const days = daysDraggable?.map((d) =>
      d.templates.map((t) => t.template_id),
    );

    const { phase_template, new_workout_templates } =
      await api.phaseTemplates.updateOne({
        phaseTemplateId: id,
        name,
        description,
        days,
        parentFolderId,
        tags: tags,
        isPinned: data.isPinned,
      });

    const phaseTemplatesToDelete = state.phaseTemplates.phaseTemplates.filter(
      (pt) => pt.deleted === true,
    );

    for (const phaseTemplate of phaseTemplatesToDelete) {
      dispatch(
        deletePhaseTemplate({
          phaseTemplateId: phaseTemplate._id,
          ignoreWorkoutTemplate: true,
        }),
      );
    }

    dispatch(addTemplatesLocal(new_workout_templates));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const deletePhaseTemplate = createAsyncThunk(
  "phaseTemplates/deletePhaseTemplate",
  async (data: {
    phaseTemplateId: string;
    ignoreWorkoutTemplate?: boolean;
  }) => {
    const { phaseTemplateId, ignoreWorkoutTemplate } = data;

    await api.phaseTemplates.deleteOne({
      phaseTemplateId: phaseTemplateId,
      ignoreWorkoutTemplate: ignoreWorkoutTemplate,
    });

    return;
  },
);

export const duplicatePhaseTemplate = createAsyncThunk(
  "phaseTemplates/duplicatePhaseTemplate",
  async (
    data: {
      phaseTemplateId: string;
    },
    { dispatch },
  ) => {
    const { phaseTemplateId } = data;

    const { phase_template, new_workout_templates } =
      await api.phaseTemplates.duplicateOne(phaseTemplateId);

    dispatch(addTemplatesLocal(new_workout_templates));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const sharePhaseTemplateToCoach = createAsyncThunk(
  "phaseTemplates/sharePhaseTemplateToCoach",
  async (
    data: {
      phaseTemplateId: string;
      trainerId: string;
    },
    { dispatch },
  ) => {
    const { phaseTemplateId, trainerId } = data;

    const { phase_template, new_workout_templates } =
      await api.phaseTemplates.shareToCoach({
        phaseTemplateId: phaseTemplateId,
        trainerId: trainerId,
      });

    dispatch(addTemplatesLocal(new_workout_templates));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const savePhaseTemplateToTrainerLibrary = createAsyncThunk(
  "phaseTemplates/savePhaseTemplateToTrainerLibrary",
  async (
    data: {
      phaseTemplateId: string;
      trainerId: string;
    },
    { dispatch },
  ) => {
    const { phaseTemplateId, trainerId } = data;

    const { phase_template, new_workout_templates } =
      await api.phaseTemplates.saveToTrainerLibrary({
        phaseTemplateId: phaseTemplateId,
        trainerId: trainerId,
      });

    dispatch(addTemplatesLocal(new_workout_templates));

    return {
      phaseTemplate: phase_template,
    };
  },
);

export const downloadPhaseTemplate = createAsyncThunk(
  "phaseTemplates/downloadPhaseTemplate",
  async (data: { phaseTemplateId: string; userId: string }) => {
    const { phaseTemplateId, userId } = data;

    const { phase, new_workouts } = await api.phaseTemplates.downloadForClient({
      phaseTemplateId: phaseTemplateId,
      userId: userId,
    });

    return { phase: phase, newWorkouts: new_workouts };
  },
);

export const savePhaseAsTemplate = createAsyncThunk(
  "phaseTemplates/savePhaseAsTemplate",
  async (
    data: {
      phaseId: string;
      tags: string[];
      name: string;
      trainerId?: string;
    },
    { getState, dispatch },
  ) => {
    const { phaseId, tags, name, trainerId } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error();
    }

    const { phase_template, workout_templates } =
      await api.phases.saveAsTemplate({
        phaseId: phaseId,
        tags: tags,
        name: name,
        trainerId: trainerId || trainer.trainer_id,
      });

    if (!trainerId) {
      dispatch(addTemplatesLocal(workout_templates));
    }

    return {
      phaseTemplate: trainerId ? null : phase_template,
    };
  },
);

export type DayDraggable = {
  draggable_id: string;
  templates: { draggable_id: string; template_id: string }[];
};

export type DndTypeTemplateLibrary =
  | "page"
  | "phase_template_day"
  | "empty_phase_template_day"
  | "workout_template"
  | "phase_template"
  | "phase_template_workout_template"
  | "folder_sidebar"
  | "folder"
  | "new_phase_template_day";

interface SliceState {
  phaseTemplates: PhaseTemplateLocal[];
  templateLibraryFolders: TemplateLibraryFolder[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  currentTab: "trainwell" | "trainer";
  phaseTemplateEditing:
    | (Omit<PhaseTemplateLocal, "_id"> & {
        _id: string | null;
        days_draggable: DayDraggable[];
      })
    | null;
  openForUserId: string | null;
  openFolderId: string | null;
  openTagId: string | null;
}

const initialState: SliceState = {
  phaseTemplates: [],
  templateLibraryFolders: [],
  status: "idle",
  error: undefined,
  currentTab: "trainer",
  phaseTemplateEditing: null,
  openForUserId: null,
  openFolderId: null,
  openTagId: null,
};

export const phaseTemplatesSlice = createSlice({
  name: "phaseTemplates",
  initialState,
  reducers: {
    resetPhaseTemplates: () => initialState,
    updateLocalPhaseTemplate: (
      state,
      action: PayloadAction<{
        workoutTemplateId: string;
        phaseTemplate: Partial<PhaseTemplateLocal>;
      }>,
    ) => {
      console.log("Redux: Update local phase template");

      const { phaseTemplate, workoutTemplateId } = action.payload;

      const index = state.phaseTemplates.findIndex(
        (t) =>
          t.type === "single" &&
          t.workout_template_ids[0][0] === workoutTemplateId,
      );

      if (index !== -1) {
        state.phaseTemplates[index] = {
          ...state.phaseTemplates[index],
          ...phaseTemplate,
        };
      }
    },
    setTemplateTab: (state, action: PayloadAction<"trainwell" | "trainer">) => {
      state.currentTab = action.payload;
    },
    openTemplateLibraryForUserId: (state, action: PayloadAction<string>) => {
      state.openForUserId = action.payload;
    },
    closeTemplateLibrary: (state) => {
      state.openForUserId = null;
      state.openFolderId = null;
      state.openTagId = null;
    },
    setTemplateNavigation: (
      state,
      action: PayloadAction<{
        openFolderId: string | null;
        openTagId: string | null;
      }>,
    ) => {
      const { openFolderId, openTagId } = action.payload;

      state.openFolderId = openFolderId;
      state.openTagId = openTagId;
    },
    setPhaseTemplateEditing: (
      state,
      action: PayloadAction<
        | (Omit<PhaseTemplate, "_id"> & {
            _id: string | null;
            days_draggable: DayDraggable[];
          })
        | null
      >,
    ) => {
      state.phaseTemplateEditing = action.payload;
    },
    updatePhaseTemplateEditing: (
      state,
      action: PayloadAction<Partial<
        PhaseTemplate & {
          days_draggable: DayDraggable[];
        }
      > | null>,
    ) => {
      state.phaseTemplateEditing = {
        ...state.phaseTemplateEditing,
        ...action.payload,
      } as any;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhaseTemplates.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchPhaseTemplates.fulfilled, (state, action) => {
      console.log("Redux: Fetched phase templates");
      state.status = "succeeded";

      const { templateLibraryFolders, phaseTemplates } = action.payload;

      state.templateLibraryFolders = templateLibraryFolders.sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      state.phaseTemplates = phaseTemplates;
    });
    builder.addCase(fetchPhaseTemplates.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(createTemplateLibraryFolder.fulfilled, (state, action) => {
      const { templateLibraryFolder } = action.payload;

      state.templateLibraryFolders.unshift(templateLibraryFolder);
    });
    builder.addCase(createWorkoutTemplate.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(createPhaseTemplate.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(updatePhaseTemplate.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      state.phaseTemplates = state.phaseTemplates.filter(
        (t) => t._id !== phaseTemplate._id,
      );

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(duplicatePhaseTemplate.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(sharePhaseTemplateToCoach.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(
      savePhaseTemplateToTrainerLibrary.fulfilled,
      (state, action) => {
        const { phaseTemplate } = action.payload;

        state.phaseTemplates.unshift(phaseTemplate);
      },
    );
    builder.addCase(savePhaseAsTemplate.fulfilled, (state, action) => {
      const { phaseTemplate } = action.payload;

      if (!phaseTemplate) {
        return;
      }

      state.phaseTemplates.unshift(phaseTemplate);
    });
    builder.addCase(updateTemplateLibraryFolder.fulfilled, (state, action) => {
      const { templateLibraryFolder } = action.payload;

      const index = state.templateLibraryFolders.findIndex(
        (f) => f._id === templateLibraryFolder._id,
      );

      if (index !== -1) {
        state.templateLibraryFolders[index] = templateLibraryFolder;
      }
    });
    builder.addCase(deletePhaseTemplate.fulfilled, (state, action) => {
      const { phaseTemplateId } = action.meta.arg;

      state.phaseTemplates = state.phaseTemplates.filter(
        (t) => t._id !== phaseTemplateId,
      );
    });
    builder.addCase(deleteTemplateLibraryFolder.fulfilled, (state, action) => {
      const { templateLibraryFolderId } = action.meta.arg;

      state.templateLibraryFolders = state.templateLibraryFolders.filter(
        (t) => t._id !== templateLibraryFolderId,
      );
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPhaseTemplates,
  updateLocalPhaseTemplate,
  setTemplateTab,
  setPhaseTemplateEditing,
  updatePhaseTemplateEditing,
  openTemplateLibraryForUserId,
  closeTemplateLibrary,
  setTemplateNavigation,
} = phaseTemplatesSlice.actions;

export default phaseTemplatesSlice.reducer;

export const selectPhaseTemplatesForCoach = (
  state: RootState,
  parentFolderId?: string | null,
) => {
  const trainer = selectPrimaryTrainer(state);

  if (parentFolderId) {
    return state.phaseTemplates.phaseTemplates.filter(
      (t) =>
        t.trainer_id === trainer?.trainer_id &&
        t.parent_folder_id === parentFolderId &&
        !t.deleted,
    );
  } else if (parentFolderId === null) {
    return state.phaseTemplates.phaseTemplates.filter(
      (t) =>
        t.trainer_id === trainer?.trainer_id &&
        !t.parent_folder_id &&
        !t.deleted,
    );
  }

  return state.phaseTemplates.phaseTemplates.filter(
    (t) => t.trainer_id === trainer?.trainer_id && !t.deleted,
  );
};

export const selectPhaseTemplateFolderById = (state: RootState, id: string) =>
  state.phaseTemplates.templateLibraryFolders.find((t) => t._id === id);

export const selectPhaseTemplatesByTag = (state: RootState, tag: string) =>
  state.phaseTemplates.phaseTemplates.filter(
    (t) => t.tags.includes(tag) && !t.deleted,
  );

export const selectPhaseTemplateFoldersByParentId = (
  state: RootState,
  id: string,
) =>
  state.phaseTemplates.templateLibraryFolders.filter(
    (t) => t.parent_folder_id === id,
  );

export const selectPhaseTemplatesByParentId = (state: RootState, id: string) =>
  state.phaseTemplates.phaseTemplates.filter(
    (t) => t.parent_folder_id === id && !t.deleted,
  );

export const selectPhaseTemplateById = (state: RootState, id: string) =>
  state.phaseTemplates.phaseTemplates.find((t) => t._id === id);

export const selectUsedPhaseTemplateTags = (
  state: RootState,
  trainerId: string,
) =>
  [
    ...new Set(
      state.phaseTemplates.phaseTemplates
        .filter((t) => t.trainer_id === trainerId)
        .map((t) => t.tags)
        .flat(),
    ),
  ].sort((a, b) => a.localeCompare(b));

export const selectCanEditTrainwellLibrary = (state: RootState) => {
  const trainer = selectPrimaryTrainer(state);

  if (!trainer) {
    return false;
  }

  return trainerHasAccess(trainer.access_roles, "trainwell_template_library");
};
